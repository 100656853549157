import { render, staticRenderFns } from "./Catering.vue?vue&type=template&id=a825c708&scoped=true"
import script from "./Catering.vue?vue&type=script&lang=js"
export * from "./Catering.vue?vue&type=script&lang=js"
import style0 from "./Catering.vue?vue&type=style&index=0&id=a825c708&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a825c708",
  null
  
)

export default component.exports