<template>
  <div class="bottom-bar">
    <p class="bottom-bar-text">
      © {{currentYear}} All Rights Reserved
    </p>
    <b-icon-instagram class="socials-bar-icon" v-on:click="openInsta"></b-icon-instagram>
    <b-icon-facebook
        class="socials-bar-icon socials-bar-icon-bottom"
        v-on:click="openFacebook"></b-icon-facebook>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  methods: {
    openInsta() {
      window.open('https://instagram.com/angiolloswfp', '_blank').focus();
    },
    openFacebook() {
      window.open('https://www.facebook.com/profile.php?id=100073310427206', '_blank').focus();
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.bottom-bar {
  color: white;
  height: 82px;
  background-color: black;
}
.bottom-bar-text {
  margin: auto;
  padding-top: 30px;
}
.socials-bar-icon {
  height: 26px;
  width: 26px;
  cursor:pointer;
  float: left;
  display: inline-block;
  margin-top: -25px;
  padding-left: 5px;
}
</style>
