<template>
  <div :class="socialsBarClass()">
    <b-icon-instagram class="socials-bar-icon" v-on:click="openInsta"></b-icon-instagram>
    <b-icon-facebook
        class="socials-bar-icon socials-bar-icon-bottom"
        v-on:click="openFacebook"></b-icon-facebook>
  </div>
</template>

<script>
export default {
  name: 'SocialsBar',
  methods: {
    openInsta() {
      window.open('https://instagram.com/angiolloswfp', '_blank').focus();
    },
    openFacebook() {
      window.open('https://www.facebook.com/profile.php?id=100073310427206', '_blank').focus();
    },
    socialsBarClass() {
      if (this.$mq === 'sm') {
        return 'socials-bar-small';
      }
      return 'socials-bar';
    },
  },
};
</script>

<style scoped>
.socials-bar {
  height: 8em;
  width: 4em;
  position: fixed;
  right: 0;
  top: 45%;
  background-color: white;
  color: black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 5;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.socials-bar-small {
  height: 6em;
  width: 3em;
  position: fixed;
  right: 0;
  top: 45%;
  background-color: white;
  color: black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 5;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.socials-bar-small .socials-bar-icon-bottom {
  margin-bottom: -2.5em;
}
.socials-bar-icon {
  margin-top: .5em;
  width: 100%;
  height: 30%;
  cursor:pointer;
}
.socials-bar-icon-bottom {
  margin-bottom: -5em;
}
</style>
