<template>
  <div>
    <b-row>
      <div class="site_logo">
        <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
      </div>
    </b-row>
    <b-row>
      <b-col class="catering_col" cols="8">
        <b-card class="catering_card" title="Catering Request">
          <b-form @submit="onSubmit" v-if="show">

            <b-form-group id="input-group-1" label="Your Name:" label-for="input-1">
              <b-form-input
                  class="catering_input"
                  id="input-1"
                  v-model="form.name"
                  placeholder="Enter name"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Email address:"
                label-for="input-2"
            >
              <b-form-input
                  class="catering_input"
                  id="input-2"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Phone Number:" label-for="input-3">
              <b-form-input
                  class="catering_input"
                  id="input-3"
                  v-model="form.name"
                  placeholder="Enter Phone #"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Comment/Details:" label-for="input-4">
              <b-form-textarea
                  class="catering_input"
                  id="input-4"
                  v-model="form.name"
                  placeholder="Enter comment or details"
                  required
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-button class="catering_button" type="submit">Submit</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'About',
  components: {
  },
  mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/enterprise.js?render=6LcbkPIjAAAAANzb3Q_XHkwz25rSFallJ4a_VG_S');
    document.head.appendChild(recaptchaScript);
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        food: null,
        checked: [],
      },
      items: [],
      fields: [],
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      // eslint-disable-next-line
      grecaptcha.enterprise.execute('6LcbkPIjAAAAANzb3Q_XHkwz25rSFallJ4a_VG_S', { action: 'login' }).then((token) => {
        console.log(token);
        console.log(JSON.stringify(this.form));
      });
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.map_embed {
  border-left: 2px solid white;
}
.about {
  margin: 20px;
  padding:15px;
  color:white;
}
.centered-div{
  height: 200px;
  margin: 10px;
  padding:5px;
  text-align: center;
  color:white;
  filter: none;
}
.catering_card {
  text-align: left;
  margin-bottom: 10px;
  height: 95%;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black!important;
  opacity: 1; /* Firefox */
}
.catering_input {
  background-color: gainsboro!important;
}
.catering_button {
  margin-top: 1.5em;
  background-color: #e41c24;
}
.catering_col {
  margin: auto auto 15em;
}
</style>
