<template>
  <div>
    <b-row>
      <div class="site_logo">
        <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
      </div>
    </b-row>
    <b-container>
      <b-row>
        <div>
          <ul class="menu_nav_list">
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('appetizersHeading')">Appetizers
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('pizzasHeading')">Pizzas
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('calzonesHeading')">Calzones
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('specialtyPiesHeading')">Specialty Pies
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('grindersHeading')">Grinders
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('stuffedBreadsHeading')">Stuffed Breads
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('wingsHeading')">Wings
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('pastaHeading')">Pasta
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('dessertsHeading')">Desserts
              </b-link>
            </li>
            |
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('beveragesHeading')">Beverages
              </b-link>
             |
            </li>
            <li class="menu_nav_list_item">
              <b-link
                  class="menu_nav_header_links"
                  @click="goto('cateringHeading')">Catering
              </b-link>
            </li>
          </ul>
        </div>
      </b-row>
      <h3 v-if="enableAps" ref="appetizersHeading" class="menu_section">Appetizers</h3>
      <b-row>
        <b-col cols="6" v-for="(main, index) in appetizers" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="main.price_special">
              <b>{{main.price_special}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
            <b-card-text class="menu_item_description">{{main.description_extra}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="pizzasHeading" class="menu_section">Pizzas</h3>
      <b-row>
        <b-col cols="6" v-for="(main, index) in pizzas" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price_small}}</b></b-card-text>
            <b-card-text v-if="main.price_special" class="menu_price">
              <b>{{main.price_special}}</b>
            </b-card-text>
            <b-card-text class="menu_price price_large">
              <b>{{main.price_large}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="calzonesHeading" class="menu_section">Calzones</h3>
      <b-row>
        <b-col cols="6" v-for="(main, index) in calzones" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price_small}}</b></b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
            <b-card-text class="menu_item_description">
              <p>Specialty Add-ins (additional charge per item)</p>
              <p>Roasted Chicken or Chicken Cutlet $2.50</p>
              <p>Broccoli Rabe or Artichoke Hearts $1.75</p>
              <p> Buffalo Sauce $0.50</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 class="menu_section">Traditional Toppings</h3>
      <b-row>
        <b-col cols="12" v-for="(main, index) in toppings" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price_small}}</b></b-card-text>
            <b-card-text class="menu_price price_large">
              <b>{{main.price_large}}</b>
            </b-card-text>
            <b-card-text v-if="main.price_cal" class="menu_price_calzone">
              <b>{{main.price_cal}}</b>
            </b-card-text>
            <b-card-text v-if="main.price_cal_large" class="menu_price">
              <b>{{main.price_cal_large}}</b>
            </b-card-text>
            <b-card-text v-if="main.price_calzone" class="menu_price">
              <b>{{main.price_calzone}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 class="menu_section">Specialty Toppings</h3>
      <b-row>
        <b-col>
          <b-card class="specialty_toppings_card" title="Specialty Toppings">
            <b-table
                table-class="table-light"
                striped
                small
                hover :items="specialtyToppings" :fields="specialtyToppingsFields"></b-table>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="specialtyPiesHeading" class="menu_section">Specialty Pies</h3>
      <b-row>
        <b-col cols="6" v-for="(main, index) in mains" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <h6 v-if="main.subtitle" class="card-title">{{main.subtitle}}</h6>
            <b-card-text class="menu_price">
              <b>Small - 12 and Cast Skillet" {{main.price_small}}</b>
            </b-card-text>
            <b-card-text class="menu_price price_large">
              <b v-if="main.price_large">Large - 16" {{main.price_large}}</b>
            </b-card-text>
            <b-card-text class="menu_price menu_price_calzone">
              <b v-if="main.price_cauliflower">Cauliflower - 10" {{main.price_cauliflower}}</b>
            </b-card-text>
            <b-card-text class="menu_price menu_price_skillet">
              <b v-if="main.price_cauliflower_large"
              >Cauliflower - 14" {{main.price_cauliflower_large}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="grindersHeading" class="menu_section">Grinders</h3>
      <b-row>
        <b-col cols="6" v-for="(main, index) in grinders" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price}}</b></b-card-text>
            <b-card-text
                v-if="!main.description"
                class="menu_item_description">{{main.title}} Grinder
            </b-card-text>
            <b-card-text  v-if="main.description"
                          class="menu_item_description"
            >{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="stuffedBreadsHeading" class="menu_section">Stuffed Breads</h3>
      <b-row>
        <b-col cols="6" v-for="(main, index) in stuffedBreads" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price}}</b></b-card-text>
            <b-card-text
                v-if="!main.description"
                class="menu_item_description">{{main.title}} Bread
            </b-card-text>
            <b-card-text  v-if="main.description"
                          class="menu_item_description"
            >{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="wingsHeading" class="menu_section">Wings</h3>
      <b-row>
        <b-col
            :cols="wings.length === 1 ? 12 : 6"
            v-for="(main, index) in wings" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="main.price_two">
              <b>{{main.price_two}}</b>
            </b-card-text>
            <b-card-text class="menu_price" v-if="main.price_three">
              <b>{{main.price_three}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="pastaHeading" class="menu_section">Pasta</h3>
      <h5 class="menu_section">Comes with a choice of soup or salad.</h5>
      <b-row>
        <b-col
            :cols="pasta.length === 1 ? 12 : 6"
            v-for="(main, index) in pasta" v-bind:key="index">
          <b-card class="special_pies_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="main.price_special">
              <b>{{main.price_special}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{main.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="dessertsHeading" class="menu_section">Desserts</h3>
      <b-row>
        <b-col cols="6" v-for="(des, index) in desserts" v-bind:key="index">
          <b-card class="special_pies_card" :title="des.title">
            <b-card-text class="menu_price"><b>{{des.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="des.price_special">
              <b>{{des.price_special}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{des.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="beveragesHeading" class="menu_section">Beverages</h3>
      <b-row>
        <b-col cols="6" v-for="(des, index) in beverages" v-bind:key="index">
          <b-card class="special_pies_card" :title="des.title">
            <b-card-text class="menu_price"><b>{{des.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="des.price_special">
              <b>{{des.price_special}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{des.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <h3 ref="cateringHeading" class="menu_section">Catering</h3>
      <b-row>
        <b-col cols="6" v-for="(des, index) in catering" v-bind:key="index">
          <b-card class="special_pies_card" :title="des.title">
            <b-card-text class="menu_price"><b>{{des.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="des.price_special">
              <b>{{des.price_special}}</b>
            </b-card-text>
            <b-card-text class="menu_item_description">{{des.description}}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <br/>
      <br/>
      <b-row>
        <b-col cols="12">
          <b-card class="alg_safety_card">
            <b-card-text class="">
              * CONSUMING RAW OR UNDERCOOKED MEATS, POULTRY, SEAFOOD, SHELLFISH OR EGGS MAY INCREASE
              YOUR RISK OF FOODBORNE ILLNESS, ESPECIALLY IF YOU HAVE CERTAIN MEDICAL CONDITIONS.
            </b-card-text>
            <b-card-text class="">
              ** While we offer gluten-free menu options, we are not a gluten-free restaurant.
              Cross-contamination could occur and our restaurant is unable to guarantee that
              any item can be completely free of allergens.
              Patrons are encouraged to their own satisfaction, to consider this information in
              light of their individual requirements and needs.
            </b-card-text>
            <b-card-text class="">
              Our store offers products with peanuts, tree nuts, soy, milk, eggs and wheat.
              While we take steps to minimize the risk of cross-contamination,
              we cannot guarantee that any of our products are safe to consumer for
              people with peanut, tree nut, soy, milk, egg or wheat allergies.
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      enableAps: true,
      sections: [
        { title: 'Appetizers', item: 'appetizers' },
        { title: 'Pizzas', item: 'pizzas' },
        { title: 'Toppings', item: 'toppings' },
        { title: 'Specialty Toppings', item: 'specialtyToppings' },
        { title: 'Specialty Pies', item: 'mains' },
        { title: 'Grinders', item: 'grinders' },
        { title: 'Desserts', item: 'desserts' },
      ],
      appetizers: [
        {
          title: 'Side of Meatballs',
          price: '$9.50',
          description: 'Three Meatballs in sauce.',
          price_special: 'Add Mozzarella or Ricotta  $1.50',
        },
        { title: 'Side of Chicken Parm', price: '$13.00', description: 'Side of our Chicken parmesan.' },
        { title: 'Side of Eggplant Parm', price: '$11.00', description: 'Side of our Eggplant parmesan.' },
        { title: 'Broccoli Rabe & Sausage', price: '$13.00', description: 'Side of our Broccoli Rabe & Sausage.' },
        {
          title: 'Side Salad',
          price: '$4.50',
          description: 'Salad greens, Shredded carrot, Grape tomatoes.',
          description_extra: 'Dressings: Balsamic vinaigrette, Parmesan peppercorn, Buttermilk Ranch. ',
          price_special: 'Add Chicken Cutlet or Roasted Chicken  $3.50',
        },
        {
          title: 'Pasta Fagioli',
          price: 'Bowl $6.50',
          description: '',
          price_special: 'Quart $12.00',
        },
        {
          title: 'Garlic Bread',
          price: '$5.50',
          description: '',
          price_special: 'Add Mozzarella $1.00',
        },
        {
          title: 'Side of Sauce',
          price: '$2.50',
          description: '',
          price_special: 'Quart $12.00',
        },
        {
          title: 'Arancini',
          price: '$13.00',
          description: '',
          price_special: '',
        },
        {
          title: 'Fried Mozzarella ',
          price: '$2.50',
          description: '',
          price_special: '',
        },
      ],
      desserts: [
        {
          title: 'Cannolis', price: '$3.50 (Each)', description: '', price_special: 'Chocolate Shell $4.50',
        },
        { title: 'Chocolate Chip Cookie', price: '$2.50', description: '' },
      ],
      toppings: [
        {
          title: 'Traditional Toppings',
          price_small: 'Small - 12" | Cast Skillet: $2.50',
          price_large: 'Large - 16": $4.50',
          price_cal: 'Cauliflower - 10": $1.25',
          price_cal_large: 'Cauliflower - 14": $2.50',
          price_calzone: 'Calzone: $1.25',
          description: 'Sausage, Pepperoni, Meatball, Bacon, Peppers, Onion, Mushrooms, Eggplant, Black Olives, Broccoli, Spinach, Anchovy, Cherry Peppers.',
        },
      ],
      pizzas: [
        {
          title: 'Plain', price_small: 'Small - 12" | Cast Skillet: $12.25', price_large: 'Large - 16": $16.25', description: 'Tomatoes and grated Pecorino Romano.',
        },
        {
          title: 'Mozzarella', price_small: 'Small - 12" | Cast Skillet: $13.50', price_large: 'Large - 16": $17.50', description: 'Plain cheese pizza with mozzarella.',
        },
        {
          title: 'Cauliflower Pizza 10" **', price_small: 'Plain: $15.00', price_large: '10" White with Mozzarella: $15.00', price_special: '10" Mozzarella: $15.50', description: 'Gluten free pizza made with Cauliflower crust.',
        },
        {
          title: 'Cauliflower Pizza 14" **', price_small: 'Plain: $17.00', price_large: 'White with Mozzarella: $17.50', price_special: 'Mozzarella: $17.50', description: 'Gluten free pizza made with Cauliflower crust.',
        },
      ],
      calzones: [
        {
          title: 'Calzone', price_small: '$14.00', description: 'Available with any of our traditional toppings for $1.25 each.',
        },
      ],
      mains: [
        {
          title: 'Margherita', price_small: '$16.00', price_large: '$22.00', description: 'Tomato Sauce, Fresh mozzarella, and Basil.', price_skillet: '$16.50', price_cauliflower: '$17.50', price_cauliflower_large: '$21.50',
        },
        {
          title: 'Fresh Tomato & Pistachio Pesto', price_small: '$18.00', price_large: '$24.50', description: 'Fresh sliced tomato, Mozzarella, and Pistachio pesto.', price_cauliflower: '$18.00', price_skillet: '$17.50', price_cauliflower_large: '$25.00',
        },
        {
          title: 'The Sunrise*', price_small: '$17.50', price_large: '', description: 'Bacon, egg, and mozzarella.',
        },
        {
          title: 'Pollo e Pepe', price_small: '$17.50', price_large: '$23.50', description: 'Oven roasted chicken, mozzarella, black pepper, and olive oil.', price_cauliflower: '$18.00', price_skillet: '$17.50', price_cauliflower_large: '$22.00',
        },
        {
          title: 'The Big Ang', price_small: '$17.50', price_large: '$24.00', description: 'Ground beef, American cheese, lettuce, pickles, onion, and thousand island dressing.', price_skillet: '$17.50', price_cauliflower: '$17.50', price_cauliflower_large: '$22.50',
        },
        {
          title: 'Black Garlic', price_small: '$18.00', price_large: '$24.00', description: 'Black garlic, Fresh tomato, Fresh mozzarella, Basil, and Balsamic glaze.', price_cauliflower: '$17.50', price_skillet: '$18.00', price_cauliflower_large: '$22.50',
        },
        {
          title: 'Meat Lovers', price_small: '$18.00', price_large: '$26.00', description: 'Sausage, Pepperoni, Bacon, Meatball, Onion.', subtitle: '(Pick Any 3)', price_cauliflower: '$18.00', price_cauliflower_large: '$24.00', price_skillet: '$18.00',
        },
        {
          title: 'Veggie Special', price_small: '$18.00', price_large: '$26.00', description: 'Onions, Mushroom, Eggplant, Broccoli, Black Olives, Hot Cherry Peppers, Peppers, Spinach.', subtitle: '(Pick Any 4)', price_cauliflower: '$18.00', price_skillet: '$18.00', price_cauliflower_large: '$24.00',
        },
        {
          title: 'Clams Casino', price_small: '$20.50', price_large: '$29.00', description: 'Clams, Bacon, Peppers.', price_cauliflower: '$19.50', price_cauliflower_large: '$25.50', price_skillet: '$25.50',
        },
        {
          title: 'Buffalo Chicken', price_small: '$18.00', price_large: '$24.00', description: 'Mozzarella, hot sauce and a choice of Roasted or Chicken Cutlet and blue cheese or ranch.', price_cauliflower: '$18.00', price_skillet: '$17.00', price_cauliflower_large: '$22.50',
        },
        {
          title: 'Pizza Alla Vodka Sauce', price_small: '$20.25', price_large: '$27.00', description: 'White pie with with mozzarella, vodka sauce, And your choice of roasted chicken or chicken cutlet topped with basil.', price_cauliflower: '$20.00', price_skillet: '$20.25', price_cauliflower_large: '$25.50',
        },
      ],
      beverages: [
        {
          title: 'Foxon Park',
          price: 'Bottle $2.50',
          price_special: 'Quart $3.75',
          description: 'Foxon Park Sodas',
        },
        { title: 'Bottled Water', price: '$1.00', description: 'Bottled Water' },
        { title: 'Beer', price: '', description: 'Corona Premier, Bud Light, Michelob Ultra, Thomas Hooker IPA, Stony Creek Big Cranky IPA' },
        { title: 'Wine', price: '', description: 'Lobster Reef Sauvignon Blanc, Jean-Luc Colombo Rose, Josh Cellars Cabernet Sauvignon' },
        { title: 'Liquor', price: '', description: 'Bacardi, Basil Haydens, Don Julio Blanco, Gordon Gin, Jameson, Johnnie Walker Black, Ketel One, Limoncello, Molinari Sambuca, Tito\'s' },
        { title: 'Coffee', price: '', description: 'Fresh Coffee' },
        { title: 'Cappuccino', price: '', description: 'Espresso and steamed milk' },
        { title: 'Espresso', price: '', description: 'Shot of espresso' },
      ],
      specialtyToppings: [
        {
          topping_name: 'Broccoli Rabe, Ricotta, Fresh Mozzarella, Artichoke Hearts, Pistachio Pesto, Vodka Sauce', price_small: '$3.50', price_large: '$5.50', price_cauliflower: '1.75', price_cauliflower_large: '$3.50',
        },
        {
          topping_name: 'Clams, Fresh Tomato, Roasted Chicken, Chicken Cutlet', price_small: '$5.00', price_large: '$7.50', price_cauliflower: '2.50', price_cauliflower_large: '$5.00',
        },
      ],
      grinders: [
        { title: 'Broccoli Rabe & Sausage', price: '$14.00' },
        { title: 'Meatball Parm', price: '$13.00' },
        { title: 'Sausage Parm', price: '$13.50' },
        { title: 'Chicken Parm', price: '$14.00' },
        { title: 'Eggplant Parm', price: '$13.50' },
        {
          title: 'Chicken Cutlet',
          price: '$13.50',
          description: 'Chicken Cutlet, Lettuce, Tomato, Mayo',
        },
        { title: 'Additional Toppings', price: '$1.50', description: 'Peppers, Mushrooms, Onions' },
      ],
      stuffedBreads: [
        { title: 'Broccoli Rabe, Sausage & Mozzarella', price: '$16.50' },
        { title: 'Eggplant Parm', price: '$15.00' },
        { title: 'Broccoli, Sausage & Mozzarella', price: '$16.00' },
        { title: 'Pepperoni & Mozzarella', price: '$15.00' },
        { title: 'Chicken Parm', price: '$16.50' },
        { title: 'Spinach, Sausage & Mozzarella', price: '$16.00' },
        { title: 'Spinach & Mozzarella', price: '$15.00' },
        { title: 'Broccoli & Mozzarella', price: '$15.00' },
        { title: 'Buffalo Chicken', price: '$16.50' },
      ],
      specialtyToppingsFields: [
        { key: 'topping_name', label: '' },
        { key: 'price_small', label: 'Small - 12 and Cast Skillet"' },
        { key: 'price_large', label: 'Large - 16"' },
        { key: 'price_cauliflower', label: 'Cauliflower 10" **' },
        { key: 'price_cauliflower_large', label: 'Cauliflower 14" **' },
      ],
      wings: [
        {
          title: 'Jumbo Buffalo wings',
          price: '6 Piece $11.00',
          description: 'Comes with a side of Blue Cheese or Ranch.',
          price_two: '12 piece  $22.00',
          price_three: '18 piece  $33.00',
        },
      ],
      pasta: [
        {
          title: 'Lasagna',
          price: '$18.00',
          description: '',
        },
        {
          title: 'Meatballs In Sauce',
          price: '$19.00',
          description: 'Choice of spaghetti or ziti',
        },
        {
          title: 'Chicken Parm',
          price: '$22.50',
          description: 'Choice of spaghetti or ziti',
        },
        {
          title: 'Eggplant Parm',
          price: '$20.50',
          description: 'Choice of spaghetti or ziti',
        },
        {
          title: 'Broccoli Rabe & Sausage',
          price: '$22.50',
          description: 'Choice of spaghetti or ziti',
        },
      ],
      salads: [

      ],
      catering: [
        {
          title: 'Chicken Parm',
          price: 'Small $55.00',
          price_special: 'Large $85.00',
          description: 'Small serves 8-10, Large 15-20.',
        },
        {
          title: 'Eggplant Parm',
          price: 'Small $50.00',
          price_special: 'Large $80.00',
          description: 'Small serves 8-10, Large 15-20.',
        },
        {
          title: 'Meatballs',
          price: 'Small $50.00',
          price_special: 'Large $80.00',
          description: 'Small serves 8-10, Large 15-20.',
        },
        { title: 'Garden Salad', price: 'Small $30.00', description: 'Small serves 8-10, Large 15-20.' },
      ],
    };
  },
  methods: {
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop + -120;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.specialty-table td {
}
.centered-div{
  height: 200px;
  margin: 30px;
  padding:50px;
  text-align: center;
  color:white;
  filter: none;
}
.site_logo{
  height: 200px;
  margin: 10px;
  padding: 5px;
  text-align: center;
  color:white;
  filter: none;
}
.card {
  color: black;
}
.custom_card {
  text-align: left;
  margin-bottom: 10px;
}
.toppings_card {
  text-align: left;
  margin-bottom: 10px;
  height: 96.5%;
}
.specialty_toppings_card {
  text-align: left;
  margin-bottom: 10px;
  overflow: scroll;
}
.menu_price {
  text-align: right;
}
.menu_price_calzone {
  text-align: right;
  padding-top: 2.5em;
}
.menu_price_skillet {
  text-align: right;
}
.menu_section {
  color: white;
}
.toppings_list {
  display: inline-block;
  max-width: 80%;
}
.menu_item_description {
  display: inline-block;
}
.price_large {
  float: right;
  display: block;
}
.pizza_list {
  padding-left: 1.5em;
}
.special_pies_card {
  text-align: left;
  margin-bottom: 10px;
  height: 95%;
}
.menu_nav_header_links {
  color: white;
  text-decoration: none;
}
.menu_nav_list {
  color: white;
  font-weight: bold;
  list-style-type: none;
}
.menu_nav_list_item {
  display: inline-block;
  font-size: 1.25em;
}
.alg_safety_card {
  background-color: #575757;
}
.price_cal {
  display: block;
}
</style>
