<template>
  <div>
    <div class="row">
      <div class="centered-div">
        <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
      </div>
    </div>
  <div class="about">
    <div class="row">
      <b-col>
        <AboutUs :shorted="false" ></AboutUs>
      </b-col>
      <b-col class="map_embed">
        <Gallery></Gallery>
      </b-col>
    </div>
  </div>
  </div>
</template>

<script>
import AboutUs from '../components/AboutUs.vue';
import Gallery from '../components/Gallery.vue';

export default {
  name: 'About',
  components: {
    Gallery,
    AboutUs,
  },
};
</script>

<style scoped>
.map_embed {
  border-left: 2px solid white;
}
.about {
  margin: 20px;
  padding:15px;
  color:white;
}
.centered-div{
  height: 200px;
  margin: 10px;
  padding:5px;
  text-align: center;
  color:white;
  filter: none;
}
</style>
