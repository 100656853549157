<template>
  <div :class="navBarWrapperClasses">
  <div :class="navClasses">
    <b-nav :class="navBarClasses" align="center">
      <b-navbar-brand href="/">
        <img :class="navBrandLogoClasses"
             src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png"
             alt=""/>
      </b-navbar-brand>
      <li :class="navLinkClasses">
        <router-link
            class="nav-link router-link-active"
            @click.native="scrollToTop" to="/">Home</router-link>
      </li>
      <li :class="navLinkClasses">
        <b-link
            class="nav-link router-link-active"
            @click="scrollToMenuSection('hoursAndLocation', -115)">Location
        </b-link>
      </li>
      <li :class="navLinkClasses">
        <b-link
            class="nav-link router-link-active"
            @click="scrollToMenuSection('aboutUs', -150)">About
        </b-link>
      </li>
      <li :class="navLinkClasses + ' ' + menuClasses">
        <router-link  @click.native="scrollToTop"
                      class="nav-link router-link-active" to="/Menu">Menu</router-link>
      </li>
        <li :class="navLinkClasses + ' ' + menuClasses">
            <router-link  @click.native="scrollToTop" class="nav-link router-link-active"
                          to="/Catering-menu">Catering Menu</router-link>
        </li>
      <li v-if="enableCatering" :class="navLinkClasses + ' ' + menuClasses">
        <router-link  @click.native="scrollToTop"
                      class="nav-link router-link-active" to="/Catering">Catering</router-link>
      </li>
      <li v-if="enableOnlineOrder" :class="navLinkClasses">
        <div v-on:click="gotoOnlineOrder" :class="orderOnlineClasses">Order Online</div>
      </li>
      <li v-if="enableOnlineOrder" :class="navLinkClasses">
        <div v-on:click="gotoGrubHub" :class="orderGHClasses">
          <img class="grubHubLogo" src="../assets/Grubhub-Emblem.png" alt=""/>
        </div>
      </li>
    </b-nav>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      enableBar: false,
      enableCatering: false,
      enableOnlineOrder: true,
    };
  },
  methods: {
    scrollToMenuSection(section, offset) {
      if (this.$router.currentRoute.name === 'Menu') {
        this.$router.push('/').then(() => {
          setTimeout(() => {
            const element = document.getElementById(section);
            const top = element.offsetTop + offset;
            window.scrollTo(0, top);
            return '';
          }, 50);
        });
      }
      const element = document.getElementById(section);
      const top = element.offsetTop + offset;
      window.scrollTo(0, top);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    gotoOnlineOrder() {
      return window.open('https://www.clover.com/online-ordering/angiollos-wood-fired-apizza-northford', '_blank');
    },
    gotoGrubHub() {
      return window.open('http://menus.fyi/4883584', '_blank');
    },
  },
  computed: {
    navClasses() {
      if (this.$mq === 'sm') {
        return 'top_nav-small';
      }
      if (!this.enableBar) {
        return 'top_nav';
      }
      return 'top_nav bottom_bar';
    },
    navBarWrapperClasses() {
      if (this.$mq === 'sm') {
        return 'nav-bar-wrapper-small';
      }
      return 'nav-bar-wrapper';
    },
    navBarClasses() {
      if (this.$mq === 'sm') {
        return 'nav-bar-small';
      }
      return 'nav-bar';
    },
    navBrandLogoClasses() {
      if (this.$mq === 'sm') {
        return 'nav-brand-logo-small';
      }
      return 'nav-brand-logo';
    },
    navLinkClasses() {
      if (this.$mq === 'sm') {
        return 'nav-item nav-link-small';
      }
      return 'nav-item nav-link';
    },
    orderOnlineClasses() {
      if (this.$mq === 'sm') {
        return 'order-online-button order-online-button-small';
      }
      return 'order-online-button';
    },
    orderGHClasses() {
      if (this.$mq === 'sm') {
        return 'order-online-button-gh order-online-button-small-gh';
      }
      return 'order-online-button-gh';
    },
    menuClasses() {
      if (this.$mq === 'sm') {
        return 'menu-link-small';
      }
      return '';
    },
  },
};
</script>

<style scoped>
.top_nav_spacer {
  padding-top: 110px;
}
.nav-bar-wrapper {
  display: table-cell;
  padding-top: 110px;
}
.top_nav {
  position:fixed;
  top:0;
  width:100%;
  z-index: 100;
  height: 110px;
  background-color: #000000;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  margin: auto;
}
.bottom_bar:after {
  content: '';
  width: 45%;
  height: 2px;
  background: white;
  bottom: -4px;
  display: inline-block;
}
.nav-link {
  width: 12%;
  display: inline-table;
}
.nav-link-small {
  width: 20%;
  display: inline-table;
}
.nav-bar-small {
  margin-left: -2em;
}
.nav-link {
  display: inline-table;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.nav-bar {
  margin-top: 1.6em;
  height: 100%;
  padding-left: 4em;
}
.nav-brand-logo {
  max-height: 100px;
  margin-top: -1.25em;
  top:0;
}
.navbar-brand {
  top: 0;
}
.nav-brand-logo-small {
  max-height: 100px;
  max-width: 25vw;
  left:0;
  padding-top: .5em;
  padding-left: .25em;
  margin: auto;
}
.order-online-button {
  width:auto;
  height: 62px;
  max-width: 130px;
  min-width: 122px;
  color: white;
  display: block;
  float: left;
  margin: auto;
  font-weight: 400;
  line-height: 3;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #af4448;
  border: 1px solid #af4448;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav-link:hover {
  color: #e41c24;
}
.nav-link:focus {
  color: #e41c24;
}
.top_nav-small {
  position:fixed;
  top:0;
  width:100%;
  z-index: 100;
  height: 120px;
  background-color: #000000;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  margin: auto;
}
.nav-bar-wrapper-small {
  display: table-cell;
  padding-top: 120px;
}
.order-online-button-small {
  margin-left: .8em;
  margin-top: -2.5em;
  width: 170%;
}
.menu-link-small {
  margin-top: -1em;
}
.order-online-button-gh {
  max-width: 130px;
  color: white;
  display: block;
  float: left;
  margin: auto;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #ff8000;
  border: 1px solid #ff8000;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.order-online-button-small-gh {
  margin-left: .8em;
  margin-top: -2.5em;
  width: 170%;
}
.grubHubLogo {
  max-width: 100px;
  max-height: 48px;
}
</style>
