<template>
  <div>
    <b-row>
      <div class="site_logo">
        <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
      </div>
    </b-row>
    <b-container>
      <h3 ref="appetizersHeading" class="menu_section">Catering</h3>
        <b-card class="menu_size">
            <b-card-text class="menu_size"><b>Small - 8 to 10 people</b></b-card-text>
            <b-card-text class="menu_size"><b>Large - 5 to 20 people</b></b-card-text>
        </b-card>
      <b-row>
        <b-col cols="6" v-for="(main, index) in appetizers" v-bind:key="index">
          <b-card class="catering_menu_card" :title="main.title">
            <b-card-text class="menu_price"><b>{{main.price}}</b></b-card-text>
            <b-card-text class="menu_price" v-if="main.price_special">
              <b>{{main.price_special}}</b>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      enableAps: true,
      sections: [
        { title: 'Appetizers', item: 'appetizers' },
        { title: 'Pizzas', item: 'pizzas' },
        { title: 'Toppings', item: 'toppings' },
        { title: 'Specialty Toppings', item: 'specialtyToppings' },
        { title: 'Specialty Pies', item: 'mains' },
        { title: 'Grinders', item: 'grinders' },
        { title: 'Desserts', item: 'desserts' },
      ],
      appetizers: [
        {
          title: 'Chicken Parmigiano',
          price: 'Small $55.00',
          description: '',
          price_special: 'Large $85.00',
        },
        {
          title: 'Chicken Marsala',
          price: 'Small $55.00',
          description: '',
          price_special: 'Large $85.00',
        },
        {
          title: 'Eggplant Parmigiano',
          price: 'Small $50.00',
          description: '',
          price_special: 'Large $80.00',
        },
        {
          title: 'Broccoli Rabe & Sausage',
          price: 'Small $55.00',
          description: '',
          price_special: 'Large $85.00',
        },
        {
          title: 'Meatballs',
          price: 'Small $50',
          description: '',
          price_special: 'Large $80.00',
        },
        {
          title: 'Baked Penne',
          price: 'Small $50',
          description: '',
          price_special: 'Large $80.00',
        },
        {
          title: 'Penne with Sauce',
          price: 'Small $30',
          description: '',
          price_special: 'Large $50.00',
        },
        {
          title: 'Lasagna',
          price: 'Small $65',
          description: '',
          price_special: 'Large $95.00',
        },
        {
          title: 'Garden Salad',
          price: 'Small $20',
          description: '',
          price_special: 'Large $40.00',
        },
        {
          title: 'Sauce',
          price: 'Quart $12',
          description: '',
        },
      ],
    };
  },
  methods: {
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop + -120;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.centered-div{
  height: 200px;
  margin: 30px;
  padding:50px;
  text-align: center;
  color:white;
  filter: none;
}
.site_logo{
  height: 200px;
  margin: 10px;
  padding: 5px;
  text-align: center;
  color:white;
  filter: none;
}
.card {
  color: black;
}
.custom_card {
  text-align: left;
  margin-bottom: 10px;
}
.toppings_card {
  text-align: left;
  margin-bottom: 10px;
  height: 96.5%;
}
.specialty_toppings_card {
  text-align: left;
  margin-bottom: 10px;
  overflow: scroll;
}
.menu_price {
  text-align: right;
}
.menu_price_calzone {
  text-align: right;
  padding-top: 2.5em;
}
.menu_price_skillet {
  text-align: right;
}
.menu_section {
  color: white;
}
.toppings_list {
  display: inline-block;
  max-width: 80%;
}
.menu_item_description {
  display: inline-block;
}
.price_large {
  float: right;
  display: block;
}
.pizza_list {
  padding-left: 1.5em;
}
.catering_menu_card {
  text-align: left;
  margin-bottom: 10px;
  height: 95%;
}
.menu_nav_header_links {
  color: white;
  text-decoration: none;
}
.menu_nav_list {
  color: white;
  font-weight: bold;
  list-style-type: none;
}
.menu_nav_list_item {
  display: inline-block;
  font-size: 1.25em;
}
.alg_safety_card {
  background-color: #575757;
}
.price_cal {
  display: block;
}
.menu_size {
    margin: auto;
    margin-bottom: 10px;
    height: 95%;
    background-color: #b0b0b0;
}
</style>
