<template>
  <div class="home">
    <section>
      <MainHero></MainHero>
    </section>
    <section>
      <b-row>
        <Hours class="elevation-4" :home-page="true" :show-title="false"></Hours>
      </b-row>
    </section>
    <section>
      <b-row>
        <about-us />
      </b-row>
    </section>
    <section>
      <b-row class="elevation-4">
        <instagram-feed />
      </b-row>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHero from '@/components/MainHeroV2.vue';
import Hours from '@/components/Hours2.vue';
import AboutUs from '@/components/AboutUs2.vue';
import InstagramFeed from '@/components/InstagramFeed2.vue';

export default {
  name: 'Home',
  data() {
    return {
      siteLive: true,
    };
  },
  methods: {
    openInsta() {
      window.open('https://instagram.com/angiolloswfp', '_blank').focus();
    },
  },
  computed: {
    sectionClass() {
      if (this.$mq === 'sm') {
        return 'home-col-section-small';
      }
      return 'home-col-section-large';
    },
    mapClass() {
      if (this.$mq === 'sm') {
        return 'home-map-small';
      }
      return 'home-map';
    },
  },
  components: {
    InstagramFeed,
    AboutUs,
    MainHero,
    Hours,
  },
};
</script>

<style scoped>
.home-map {
  padding-top: 1em;
  padding-left: 1em;
}
.home-map-small{

}
.home-col-section-large {
  min-width: 10em;
  width: 30%;
  padding-top: 1.3em;
}
.home-col-section-small {
  min-width: 10em;
  width: 100%;
}
</style>
