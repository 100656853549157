import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Location from '../views/Location.vue';
import Menu from '../views/Menu.vue';
import MenuV2 from '../views/MenuV2.vue';
import Catering from '../views/Catering.vue';
import CateringMenu from '../views/CateringMenu.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/location',
    name: 'Location',
    component: Location,
  },
  {
    path: '/Menu',
    name: 'Menu',
    component: Menu,
  },
  {
    path: '/Catering-menu',
    name: 'CateringMenu',
    component: CateringMenu,
  },
  {
    path: '/MenuV2',
    name: 'MenuV2',
    component: MenuV2,
  },
  {
    path: '/Catering',
    name: 'Catering',
    component: Catering,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
