<template>
  <div id="app">
    <Nav></Nav>
    <router-view/>
    <bottom-bar />
    <socials-bar />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100vw;
  margin: auto;
  overflow: hidden;
}

</style>
<script>
import BottomBar from '@/components/BottomBar.vue';
import SocialsBar from '@/components/SocialsBar.vue';
import Nav from './components/Nav.vue';

export default {
  components: { SocialsBar, BottomBar, Nav },
};
</script>
