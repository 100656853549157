<template>
  <div id="hoursAndLocation" :class="hoursWrapperClass()">
    <div :class="containerClass() + ' '  + hoursClass()">
      <div :class="cardClasses" :title="title">
        <div>
          <b-table
              tbody-tr-class="hours-table-tr"
              thead-tr-class="hours-table-tr"
              :items="hours">
          </b-table>
        </div>
      </div>
    </div>
    <div :class="containerClass() + ' card-link center-block'">
      <h3>
        Angiollo's Wood Fired Apizza - Northford
      </h3>
      <p>
        Located at 1421 Middletown Ave In Northford, CT 06472
      </p>
      <p>We're open 5 days a week for dine in or takeout.</p>
      <a class="card-link" href="tel:203-208-0170">Call us today: (203) 208-0170</a>
      <div v-if="enableOnlineOrder"
           v-on:click="gotoOnlineOrder" class="order-online-button">Order Online</div>
    </div>
    <div :class="containerClass() + ' ' + mapClass()">
      <iframe
          width=""
          height="275"
          style="border:0"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCCa6_nSmSHw6OJMagzILci_esSrrv1WK4
    &q=1421+Middletown+Ave,+Northford,+CT+06472">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hours',
  data() {
    return {
      enableOnlineOrder: true,
      items: [
        { day: 'Monday/Tuesday', hours: 'Closed' },
        { day: 'Wednesday', hours: '5pm - 12pm' },
        { day: 'Thursday', hours: '5pm - 12pm' },
        { day: 'Friday/Saturday', hours: '5pm - 1am' },
      ],
      hours: [
        { day: 'Wednesday', hours: '3pm - 8pm' },
        { day: 'Thursday', hours: '3pm - 8pm' },
        { day: 'Friday', hours: '3pm - 9pm' },
        { day: 'Saturday', hours: '3pm - 9pm' },
        { day: 'Sunday', hours: '3pm - 8pm' },
      ],
    };
  },
  methods: {
    gotoOnlineOrder() {
      return window.open('https://www.clover.com/online-ordering/angiollos-wood-fired-apizza-northford', '_blank');
    },
    containerClass() {
      if (this.$mq === 'sm') {
        return 'hours-loc-container-small';
      }
      return 'hours-loc-container';
    },
    hoursWrapperClass() {
      if (this.$mq === 'sm') {
        return 'hours-wrapper-small';
      }
      return 'hours-wrapper';
    },
    hoursClass() {
      if (this.$mq === 'sm') {
        return 'left-block-small';
      }
      return 'left-block';
    },
    mapClass() {
      if (this.$mq === 'sm') {
        return 'right-block-small';
      }
      return 'right-block';
    },
  },
  computed: {
    title() {
      if (this.showTitle) {
        return 'Hours';
      }
      return '';
    },
    cardClasses() {
      if (this.homePage) {
        return 'card card_home_page';
      }
      return 'card card_pad_left';
    },
  },
  props: {
    showTitle: { type: Boolean, default: true },
    homePage: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.card {
  background-color: #282727;
  color: white;
  border: none;
  text-align: left;
  horiz-align: right;
  display: inline-block;
  width: 100%;
}
.table {
  color:white;
}
.card-link {
  color:white;
}
.hours-loc-container {
  width: 30%;
  display: inline-block;
}
.hours-loc-container-small {
  width: 100%;
  display: block;
}
.left-block {
  float: left;
  padding-left: 1em;
}
.left-block-small {
  float: left;
}
.right-block {
  float: right;
}
.right-block-small {
  float: right;
}
.right-block-small iframe {
  padding-top: 1em;
  width: 100%;
}
.hours-wrapper {
  padding-top: 1em;
  padding-bottom: .6em;
}
.hours-wrapper-small {
  padding-top: 1em;
  padding-bottom: .6em;
  width: 100%;
}
.center-block {
  text-align: center;
}
.order-online-button {
  width: 35%;
  color:#af4448;
  display: block;
  margin: .5em auto auto;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #af4448;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
