<template>
  <div class="insta-feed-wrapper">
    <b-card-group deck>
      <a
          v-for="displayImage in images.slice(0, 10)"
          :key="displayImage.link"
          :href="displayImage.link"
          target="_blank"
      >
      <b-card
          :class="instaCardClass() + ' insta-img-boarder insta-img-boarder-anim'"
          title=""
          :img-src=displayImage.image img-alt="Image from instagram" img-top>
      </b-card>
      </a>
    </b-card-group>
  </div>
</template>

<script>
export default {
  name: 'InstagramFeed',
  data() {
    return {
      images: [
        {
          image: 'https://scontent-mia3-2.cdninstagram.com/v/t51.2885-15/289610564_1850523308475230_4021076181701546604_n.jpg?stp=dst-jpg_e15_fr_p1080x1080&_nc_ht=scontent-mia3-2.cdninstagram.com&_nc_cat=102&_nc_ohc=noK0Yut9RLsAX_UicfZ&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT81FZaK_pBPbiv7VjVgm6qBv_Mps0bQfoHdgMUWtb2KLQ&oe=62BA90E9&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CfMj2PxMmhZ',
        },
        {
          image: 'https://scontent-mia3-2.cdninstagram.com/v/t51.2885-15/287011545_394100052735341_2016830776237601993_n.webp?stp=dst-jpg_e35_s1080x1080&_nc_ht=scontent-mia3-2.cdninstagram.com&_nc_cat=105&_nc_ohc=08-0Vt0q3d0AX-BgS2I&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT_JeVMaP9r7ng9xTpFr3aqyjYpgDPKpi5-x4ggbTEfjIQ&oe=62BFB8AF&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CerWN8RJzXH',
        },
        {
          image: 'https://scontent-mia3-2.cdninstagram.com/v/t51.2885-15/286399153_989747055016125_1089683481782335112_n.webp?stp=dst-jpg_e35_p1080x1080&_nc_ht=scontent-mia3-2.cdninstagram.com&_nc_cat=109&_nc_ohc=KYfCsK5CMooAX_4JHnX&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT_fvpJQnNLylYejspi_XeZ979MWE_tBE8mUp5V578afHw&oe=62BEA609&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/Cel_G3kp9cA',
        },
        {
          image: 'https://scontent-mia3-1.cdninstagram.com/v/t51.2885-15/284042374_552385986250885_4685146706886960227_n.webp?stp=dst-jpg_e35_s1080x1080&_nc_ht=scontent-mia3-1.cdninstagram.com&_nc_cat=101&_nc_ohc=gWNGCzlkiZMAX-f0LSV&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT8wI-x0Jxdo6jNMbaVYxBKBaH6kdqxVuunA_yuWWJB5pw&oe=62BE6948&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CeHQ6KapfTO',
        },
        {
          image: 'https://scontent-mia3-1.cdninstagram.com/v/t51.2885-15/284032909_954951138531390_4447946958634996263_n.jpg?stp=dst-jpg_e15_fr_p1080x1080&_nc_ht=scontent-mia3-1.cdninstagram.com&_nc_cat=106&_nc_ohc=GBTIt0vvixYAX_EtQ_K&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT-LiUz2d3606gZQy4MrOzAbK0uzJSnCdZ4DgF0CMV2PQA&oe=62BA7A7F&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CeHP-L8JuN_',
        },
        {
          image: 'https://scontent-mia3-2.cdninstagram.com/v/t51.2885-15/284488936_1157573528356457_4909192291627639744_n.jpg?stp=dst-jpg_e15_fr_p1080x1080&_nc_ht=scontent-mia3-2.cdninstagram.com&_nc_cat=105&_nc_ohc=yBvN1OojYjAAX8aN0QO&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT9xnLY0Xccu6cBg_th0OpnW_GarEAU8rFTyzrD4Wp34SQ&oe=62BA832C&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CeEwuqcpI0b',
        },
        {
          image: 'https://scontent-mia3-1.cdninstagram.com/v/t51.2885-15/281900818_900906960889115_348529833137952591_n.webp?stp=dst-jpg_e35_p1080x1080&_nc_ht=scontent-mia3-1.cdninstagram.com&_nc_cat=111&_nc_ohc=YBeqmsvRUPgAX8tKtqM&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT9MBNhSzURE1FcBjJkErOshWAWxnySUgdRX6d8hCb471Q&oe=62BF3D99&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/Cd1VSY_J2Q3',
        },
        {
          image: 'https://scontent-mia3-2.cdninstagram.com/v/t51.2885-15/281923433_408907487751619_1219012664971347314_n.webp?stp=dst-jpg_e35_s1080x1080&_nc_ht=scontent-mia3-2.cdninstagram.com&_nc_cat=107&_nc_ohc=8h3ThT_4vRMAX9nVSy4&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT8Vj-6O1EF0GUlR4cXUUS4GdzaV9eC-OaCr90s0qGz7mg&oe=62BFD475&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CdwqMG9pTBp',
        },
        {
          image: 'https://scontent-mia3-1.cdninstagram.com/v/t51.2885-15/280621509_398195112203184_2247978826431721932_n.webp?stp=dst-jpg_e35&_nc_ht=scontent-mia3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=tGBb6y0tBXcAX_QaApw&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT_UvjRTzhxfoScUSqLFJ2QHajH_K4Y-QpPl51NvX-sOwQ&oe=62BF182C&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/CdgqoIBpsKa',
        },
        {
          image: 'https://scontent-mia3-2.cdninstagram.com/v/t51.2885-15/280763639_425442418918358_4252014701654648932_n.webp?stp=dst-jpg_e35&_nc_ht=scontent-mia3-2.cdninstagram.com&_nc_cat=102&_nc_ohc=IzeuEsBtBgYAX-xcyc1&edm=AAuNW_gBAAAA&ccb=7-5&oh=00_AT_tE0-_CYd9E5wT9fr5ZYKRUbCBvd4pv2lo7KuSfXuFrw&oe=62BFD131&_nc_sid=498da5',
          link: 'https://www.instagram.com/p/Cdgle9IpKpe',
        },
      ],
    };
  },
  mounted() {
    this.getInstagramImages();
  },
  computed: {
  },
  methods: {
    async getInstagramImages() {
      const response = await fetch('/api/images');
      const images = await response.json();
      if (response.ok) {
        this.images = images;
      }
    },
    instaCardClass() {
      if (this.$mq === 'sm') {
        return 'insta-image-card-small';
      }
      return 'insta-image-card';
    },
  },
  props: {
    showTitle: { type: Boolean, default: true },
  },
};
</script>

<style scoped>
.card {
  background-color: #282727;
  color: white;
  border: none;
  text-align: left;
  horiz-align: center;
}
.insta-image-card {
  max-width: 20%;
  display: inline-block;
  max-height: 320px;
  overflow: hidden;
}
.insta-image-card-small {
  max-width: 50%;
  display: inline-block;
  max-height: 220px;
  overflow: hidden;
}
.insta-feed-wrapper {
  padding-top: 1em;
}

.insta-img-boarder {
  color: #000000;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  transition: 0.5s;

  cursor: pointer;
  border: 0px solid;
  -webkit-transition:0.5s;
}

.insta-img-boarder:hover {
  border: 2px solid rgba(0,160,80,0);
  color: #FFF;
}

.insta-img-boarder::before, .insta-img-boarder::after {
  width: 100%;
  height:100%;
  z-index: 3;
  content:'';
  position: absolute;
  top:0;
  left:0;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transition: 0.5s;
}

.insta-img-boarder-anim::before {
  border-bottom: 3px solid #e41c24;
  border-left: 3px solid #e41c24;
  -webkit-transform-origin: 0 100%;
}

.insta-img-boarder-anim::after {
  border-top: 3px solid #e41c24;
  border-right: 3px solid #e41c24;
  -webkit-transform-origin: 100% 0%;
}
.insta-img-boarder:hover::after, .insta-img-boarder:hover::before {
  -webkit-transform: scale(1);
}
</style>
