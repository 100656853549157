import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueSilentbox from 'vue-silentbox';
import VueMq from 'vue-mq';
import App from './App.vue';
import router from './router';

import './app.scss';

Vue.use(VueSilentbox);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'lg',
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
