<template>
  <div>
    <silent-box :gallery="galleryImages"></silent-box>
  </div>
</template>

<script>
import imageOne from '../assets/278442180_154230730406738_8912464215754136149_n.jpeg';
import imageTwo from '../assets/278491081_387719583207297_6452658986172744175_n.jpeg';
import imageThree from '../assets/278650905_298025622345292_4671533128761586924_n.jpeg';
import imageFour from '../assets/PXL_20220304_221936391.jpeg';
import imageFive from '../assets/big_ang.png';

export default {
  name: 'Gallery',
  components: {
  },
  computed: {
    galleryImages() {
      return [
        {
          src: imageOne,
          description: 'Mozzarella Pizza.',
          alt: 'Mozzarella Pizza.',
          thumbnailWidth: '220px',
        },
        {
          src: imageTwo,
          alt: '',
          description: 'Pizza oven one.',
          thumbnailWidth: '220px',
        },
        {
          src: imageThree,
          alt: '',
          description: 'More Pizza oven.',
          thumbnailWidth: '220px',
        },
        {
          src: imageFour,
          alt: '',
          description: 'More Pizza oven.',
          thumbnailWidth: '220px',
        },
        {
          src: imageFive,
          alt: '',
          description: 'More Pizza oven.',
          thumbnailWidth: '220px',
        },
      ];
    },
  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>

<style scoped>
</style>
