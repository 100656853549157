<template>
  <div class="main-hero-wrapper">
    <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="20000"
        controls
        indicators
        fade
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        label-next=""
        label-prev=""
    >
      <!-- Text slides with image -->
      <b-carousel-slide>
        <template v-slot:img>
          <section>
            <div class="video-wrapper">
              <video
                  id="video-one"
                  ref="videoOne"
                  muted
                  autoplay="autoplay"
                  playsinline="" loop="loop" preload="auto">
                <source
                    src="/media/Pxl20220626-210800730.mp4"
                    type="video/mp4">
              </video>
            </div>
          </section>
        </template>
        <div :class="centeredDivClass">
          <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
          <a :class="callLinkClass" href="tel:203-208-0170">Call us today: (203) 208-0170</a>
        </div>
      </b-carousel-slide>
      <!-- Sample Slide.
      <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
        <div :class="centeredDivClass">
          <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
          <a :class="callLinkClass" href="tel:203-208-0170">Call us today: (203) 208-0170</a>
        </div>
      </b-carousel-slide>
      -->
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'MainHero',
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
    this.checkIfAutoPlayBlocked();
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    checkIfAutoPlayBlocked() {
      setTimeout(() => {
        if (this.$refs.videoOne.paused) {
          this.$refs.videoOne.play();
          return;
        }
        this.checkIfAutoPlayBlocked();
      }, 1000);
    },
  },
  computed: {
    containerClass() {
      if (this.$mq === 'sm') {
        return 'ct-section-small';
      }
      return 'ct-section-large';
    },
    containerBackgroundClass() {
      if (this.$mq === 'sm') {
        return 'custom-container-bg-small';
      }
      return 'custom-container-bg-large';
    },
    centeredDivClass() {
      if (this.$mq === 'sm') {
        return 'centered-div-small';
      }
      return 'centered-div';
    },
    callLinkClass() {
      if (this.$mq === 'sm') {
        return 'call-link-main-logo-small';
      }
      return 'call-link-main-logo';
    },
  },
};
</script>

<style scoped>

.ct-section-large{
  width: 100vw;
  height: 65vh;
  /* center a div insie another div*/
  display: flex;
  flex-direction: row;
  backdrop-filter: blur(5px);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5em;
}

.ct-section-small{
  width: 100%;
  display: inline-grid;
  flex-direction: unset;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.custom-container-bg-small{
  background-image: url("../assets/PXL_20220216_211930954.jpeg");
  background-size: 100% 100%;
  width: 50em;
  height: 65vh;
  /* Add the blur effect */
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.custom-container-bg-large{
  background-image: url("../assets/PXL_20220216_211930954.jpeg");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  width: 80%;
  object-fit: cover;
  height: 70vh;
  /* Add the blur effect */
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.centered-div {
  height: 22vw;
  text-align: center;
  color: white;
  filter: none;
  margin: auto auto 12vw;
}
.centered-div-small {
  height: 15vh;
  text-align: center;
  color: white;
  filter: none;
  margin: auto;
}
.main_logo {
  height: 100%;
}

.call-link-main-logo {
  display: block;
  color: white;
  font-size: large;
  font-weight: bold;
  margin-top: -3vw;
}
.call-link-main-logo-small {
  display: block;
  color: white;
  font-size: smaller;
  font-weight: bold;
  margin-top: -2.2em;
}
.video-wrapper video {
  aspect-ratio: 2048 / 945;
  width: 100%;
  object-fit: cover;
}
</style>
