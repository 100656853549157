import { render, staticRenderFns } from "./Hours2.vue?vue&type=template&id=44f7516d&scoped=true"
import script from "./Hours2.vue?vue&type=script&lang=js"
export * from "./Hours2.vue?vue&type=script&lang=js"
import style0 from "./Hours2.vue?vue&type=style&index=0&id=44f7516d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f7516d",
  null
  
)

export default component.exports