<template>
  <div>
    <div v-bind:class = "(shorted)?'card':'card full_size_card'">
      <h4 data-v-4fd998ea="" class="card-title">About Us</h4>
      <div>
        <p>Pizza runs in the blood of the Angiollo Family. They are an Italian family local to the
          Northford area.
        Marc Sr., Angela, and Nicholas have been residents of the Northford area
          for around 10 years.
        Marc Jr. had lived there previously, so he too is well-versed and feels
          connected to the area.
        </p>
        <p>They have been making pizza and Italian dishes for many decades.
        The pizza skill runs in their family. Marc Sr.’s Uncle, Angelo Angiollo, used to own
          and run Angelo’s Pizzeria.
        If the name sounds familiar,
        it’s because it was a well-known restaurant opened in North Haven in 1963 and
          he was open for over 40 years.
        Marc Sr. and Angela took ownership of Angelo’s Pizzeria alongside Angelo in 2003.
        A little something about Angelo, he gained his knowledge by working at Pepe’s
          under Frank Pepe.
        It sparked a love of pizza that spurred him to open Angelo’s Pizzeria.
        Angelo’s pizza was renowned and scrumptious.
        </p>
        <p>
        Expect nothing less of Angiollo’s pizzas!
        It was this love of Pizza that sparked everything, and that love has been
          carried down with each generation!
        So come by and enjoy Angiollo’s Wood Fired Apizza!
        </p>
      </div>

      <a class="call_link" href="tel:203-208-0170">(203) 208-0170</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hours',
  props: {
    shorted: { type: Boolean, default: true },
  },
};
</script>

<style scoped>
.card {
  background-color: #282727;
  color: white;
  border: none;
  text-align: left;
  max-width: 350px;
}
.call_link {
  color:white;
}
.full_size_card {
  width: 100%;
  height: 100%;
  max-width: 1200px;
}
</style>
