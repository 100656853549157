<template>
  <div>
    <div :class="cardClasses" :title="title">
      <div>
        <b-table
          :items="hours">
        </b-table>
      </div>
      <router-link v-if="!orderOnlineDisable" class="card-link" to="">Order Online</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hours',
  data() {
    return {
      orderOnlineDisable: true,
      items: [
        { day: 'Monday/Tuesday', hours: 'Closed' },
        { day: 'Wednesday', hours: '5pm - 12pm' },
        { day: 'Thursday', hours: '5pm - 12pm' },
        { day: 'Friday/Saturday', hours: '5pm - 1am' },
      ],
      hours: [
        { day: 'Wednesday', hours: '3pm - 8pm' },
        { day: 'Thursday', hours: '3pm - 8pm' },
        { day: 'Friday', hours: '3pm - 9pm' },
        { day: 'Saturday', hours: '3pm - 9pm' },
        { day: 'Sunday', hours: '3pm - 8pm' },
      ],
    };
  },
  computed: {
    title() {
      if (this.showTitle) {
        return 'Hours';
      }
      return '';
    },
    cardClasses() {
      if (this.homePage) {
        return 'card card_home_page';
      }
      return 'card card_pad_left';
    },
  },
  props: {
    showTitle: { type: Boolean, default: true },
    homePage: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.card {
  background-color: #282727;
  color: white;
  border: none;
  text-align: left;
  horiz-align: right;
}
.card_pad_left {
  padding-left: 30%;
}
.card_home_page {
  padding-top: 1em;
}
.table {
  color:white;
}
.card-link {
  color:white;
}
</style>
