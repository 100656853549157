<template>
  <div class="about">
    <div class="row">
      <div class="centered-div">
        <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
      </div>
    </div>
    <div class="row">
      <div style="width: 49%">
        <Hours></Hours>
      </div>
      <div class="vert_split">
      </div>
      <div style="width: 49%">
        <iframe
          width="650"
          height="550"
          style="border:0"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCCa6_nSmSHw6OJMagzILci_esSrrv1WK4
    &q=1421+Middletown+Ave,+Northford,+CT+06472">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Hours from '../components/Hours.vue';

export default {
  name: 'Location',
  components: {
    Hours,
  },
};
</script>

<style scoped>
.vert_split {
  border-right: 3px solid white;
  width: 2%;
  height: 550px;
  margin-left: -10px;
  text-align: center;
  color:white;
  filter: none;
}
.centered-div{
  height: 200px;
  margin: 10px;
  padding:5px;
  text-align: center;
  color:white;
  filter: none;
}
</style>
