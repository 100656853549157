<template>
  <div>
    <b-row>
      <div class="site_logo">
        <img class="main_logo" src="../assets/Angiollos_Brick_Oven_Apizza_Logo_red.png" alt=""/>
      </div>
    </b-row>
    <b-container>

    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MenuV2',
  data() {
    return {
      enableAps: true,
      appetizers: [
        { title: 'Side of Meatballs', price: '$6.00', description: 'Three Meatballs in sauce.' },
      ],
      mains: [
        {
          title: 'Margherita', price_small: '$16.00', price_large: '$22.00', description: 'Pomodori, Fresh mozzarella, and Basil.',
        },
        {
          title: 'Fresh Tomato & Pistachio Pesto', price_small: '$18.00', price_large: '$24.50', description: 'Fresh sliced tomato, Mozzarella, and Pistachio pesto.',
        },
        {
          title: 'The Robin', price_small: '$17.50', price_large: '', description: 'Bacon, egg, and mozzarella.',
        },
        {
          title: 'The Dave', price_small: '$17.50', price_large: '$23.50', description: 'Oven roasted chicken, mozzarella, black pepper, and olive oil.',
        },
        {
          title: 'The Big Ang', price_small: '$17.50', price_large: '$24.00', description: 'Ground beef, American cheese, lettuce, pickles, onion, and thousand island dressing.',
        },
        {
          title: 'Black Garlic', price_small: '$18.00', price_large: '$24.00', description: 'Black garlic, Fresh tomato, Fresh mozzarella, Basil, and Balsamic glaze.',
        },
        {
          title: 'Meat Lovers', price_small: '$18.00', price_large: '', description: 'Sausage, Pepperoni, Bacon, Meatball, Onion.', subtitle: '(Pick Any 3)',
        },
        {
          title: 'Veggie Special', price_small: '$18.00', price_large: '', description: 'Onions, Mushroom, Eggplant, Broccoli, Black Olives, Hot Cherry Peppers, Peppers, Spinach.', subtitle: '(Pick Any 4)',
        },
      ],
      beer_wine: [
        { name: 'MacBook Air', price: '$5.2' },
      ],
      specialtyToppings: [
        {
          topping_name: 'Roasted Chicken', price_small: '$5.00', price_large: '$7.50', price_cast: '$5.00', price_cauliflower: '$2.50',
        },
        {
          topping_name: 'Broccoli Rabe', price_small: '$3.50', price_large: '$5.50', price_cast: '$3.50', price_cauliflower: '$1.75',
        },
        {
          topping_name: 'Ricotta', price_small: '$3.50', price_large: '$5.50', price_cast: '$3.50', price_cauliflower: '$1.75',
        },
        {
          topping_name: 'Fresh Mozzarella', price_small: '$3.50', price_large: '$5.50', price_cast: '$3.50', price_cauliflower: '$1.75',
        },
        {
          topping_name: 'Artichoke Hearts', price_small: '$3.50', price_large: '$5.50', price_cast: '$3.50', price_cauliflower: '$1.75',
        },
        {
          topping_name: 'Pistachio Pesto', price_small: '$3.50', price_large: '$5.50', price_cast: '$3.50', price_cauliflower: '$1.75',
        },
        {
          topping_name: 'Hot Oil', price_small: '$2.50', price_large: '$4.50', price_cast: '$2.50', price_cauliflower: '$2.50',
        },
        {
          topping_name: 'Clams', price_small: '$5.00', price_large: '$7.50', price_cast: '$5.00', price_cauliflower: '$2.50',
        },
        {
          topping_name: 'Fresh Tomato', price_small: '$5.00', price_large: '$7.50', price_cast: '$5.00', price_cauliflower: '$2.50',
        },
      ],
      grinders: [
        { title: 'Broccoli Rabe & Sausage', price: '$11.00' },
        { title: 'Meatball Parm', price: '$9.50' },
        { title: 'Sausage Parm', price: '$9.50' },
        { title: 'Chicken Parm', price: '$10.50' },
        { title: 'Eggplant Parm', price: '$9.50' },
        { title: 'Additional Toppings', price: '$1.50', description: 'Peppers, Mushrooms, Onions' },
      ],
      specialtyToppingsFields: [
        { key: 'topping_name', label: '' },
        { key: 'price_small', label: 'Small - 12"' },
        { key: 'price_large', label: 'Large - 16"' },
        { key: 'price_cast', label: 'Cast Skillet' },
        { key: 'price_cauliflower', label: 'Cauliflower' },
      ],
    };
  },
};
</script>

<style scoped>
.centered-div{
  height: 200px;
  margin: 30px;
  padding:50px;
  text-align: center;
  color:white;
  filter: none;
}
.site_logo{
  height: 200px;
  margin: 10px;
  padding: 5px;
  text-align: center;
  color:white;
  filter: none;
}
.card {
  color: black;
}
.custom_card {
  text-align: left;
  margin-bottom: 10px;
}
.toppings_card {
  text-align: left;
  margin-bottom: 10px;
  height: 96.5%;
}
.specialty_toppings_card {
  text-align: left;
  margin-bottom: 10px;
  overflow: scroll;
}
.menu_price {
  text-align: right;
}
.menu_section {
  color: white;
}
.toppings_list {
  display: inline-block;
  max-width: 80%;
}
.menu_item_description {
  display: inline-block;
}
.price_large {
  float: right
}
.pizza_list {
  padding-left: 1.5em;
}
.special_pies_card {
  text-align: left;
  margin-bottom: 10px;
  height: 95%;
}
</style>
